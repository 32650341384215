import React, { useEffect, useState } from 'react'
// import { FaTwitter, FaDiscord } from 'react-icons/fa';
// import Nav from './Nav';
// import Mint from './Mint';
// import WebFont from 'webfontloader';
import Web3 from "web3"
import abi from "./abi"
// import ReactPlayer from 'react-player'




// import Web3Modal from "web3modal"
import './App.css';

// import nfts from './nfts.json'
// import Fade from 'react-reveal/Fade';
// import Reveal from './Reveal'

function App() {
  const [accountAddress, setAccountAddress] = useState()
  const [ownerBalances, setOwnerBalances] = useState({})
  const [userBalances, setUserBalances] = useState({})
  const [totalSupplies, setTotalSupplies] = useState({})
  const [extras, setExtras] = useState([])
  const [curId, setCurId] = useState(5)
  const [startAt, setStartAt] = useState(6)

	console.log(curId,setStartAt)

  const requiredChainId = 4
  const contractAddress = "0x936d240DcDDdd2db4c93DD1739e9F0691E923017"

  let osUrl = `https://testnets.opensea.io/assets/0x936d240DcDDdd2db4c93DD1739e9F0691E923017`
  let owner = "0x3c3028543c9e613f470a20aacd2c9f8018200b0c"

	async function get(uri) {
		let response = await fetch(uri)
		if(response){
			return response.json()
		} else {
			return {}
		}
	}

	useEffect(() => {
		if (window.web3) {
			let web3 = new Web3(window.web3.currentProvider)
			const contract = new web3.eth.Contract(abi, contractAddress)
			Promise.all([
				contract.methods.curId().call(),
				contract.methods.extrasStartAt().call(),
			]).then((arr) => {
				let [curId, startAt] = arr.map(n => Number(n))
				console.log({curId, startAt})
				let ids = []
				for(let i = startAt; i <= curId; i++) ids.push(i)
				console.log(ids)
				Promise.all(ids.map(id => contract.methods.uri(id).call())).then(uris => {
					console.log(uris)
					Promise.all(uris.map(uri => get(uri))).then(metadatas => {
						for(let i in ids){
							metadatas[i].id = ids[i]
						}
						setExtras(metadatas)

					})
				})
			})
		}
	}, [])

	async function connect(){
		if (!window.web3) {
			alert("No Metamask!")
			return
		}
		let accounts = await window.ethereum.enable()
		setAccountAddress(accounts[0])
		let web3 = new Web3(window.web3.currentProvider)

		web3.eth.getChainId().then(chainId => {
			if (chainId !== requiredChainId) {
				alert(`Please Switch to the Correct Network (${requiredChainId === 4 ? "Rinkeby" : "Mainnet"})`)
			}
			return
		})

		const contract = new web3.eth.Contract(abi, contractAddress)

		console.log("curId")
		Promise.all([
			contract.methods.curId().call(),
			contract.methods.extrasStartAt().call(),
		]).then((arr) => {
			let [curId] = arr.map(n => Number(n))
			setCurId(curId)
			let ids = [...Array(curId).keys()].map(i => i + 1)
			console.log({ids})
			Promise.all(ids.map(id => contract.methods.balanceOf(owner, id).call())).then(balances => setOwnerBalances(balances.map(b => parseInt(b))))
			Promise.all(ids.map(id => contract.methods.balanceOf(accounts[0], id).call())).then(balances => setUserBalances(balances.map(b => parseInt(b))))
			Promise.all(ids.map(id => contract.methods.totalSupply(id).call())).then(balances => setTotalSupplies(balances.map(b => parseInt(b))))
		})
	}

  // useEffect(() => {
  //   if(accountAddress){
  //     if (!window.web3) {
  //       // safari on iphone?
  //       return
  //     }

  //     let web3 = new Web3(window.web3.currentProvider)

  //     web3.eth.getChainId().then(chainId => {
  //       if (chainId !== requiredChainId) {
  //         alert(`Please Switch to the Correct Network (${requiredChainId})`)
  //       }
  //       console.log(chainId)
  //     })

  //     const contract = new web3.eth.Contract(abi, contractAddress)

  //     console.log("curId")
  //     contract.methods.curId().call().then((id) => {
  //       setCurId(parseInt(id))
  //       let ids = [...Array(parseInt(id)).keys()].map(i => i + 1)
  //       // console.log({ids})
  //       Promise.all(ids.map(id => contract.methods.balanceOf(owner, id).call())).then(balances => setOwnerBalances(balances.map(b => parseInt(b))))
  //       Promise.all(ids.map(id => contract.methods.balanceOf(accountAddress, id).call())).then(balances => setUserBalances(balances.map(b => parseInt(b))))
  //       Promise.all(ids.map(id => contract.methods.totalSupply(id).call())).then(balances => setTotalSupplies(balances.map(b => parseInt(b))))
  //     })

  //   } else {
  //     window.ethereum.enable().then(accounts => setAccountAddress(accounts[0]))
  //   }
  // // eslint-disable-next-line
  // }, [accountAddress])

  return <>
    {/* <!-- header-area start --> */}
    <header className="header-area">
			<div className="container">
				<div className="header-area-inner">
					<nav className="navbar navbar-expand-md">
					  	<a className="navbar-brand" href="/">
					  		<img src="images/logo.png" alt="" />
					  	</a>
					    <div className="hamburger hamburger--spring navbar-toggler"  data-toggle="collapse" data-target="#menu1">
						    <div className="hamburger-box">
						      <div className="hamburger-inner"></div>
						    </div>
						</div>
					  	<div className="collapse navbar-collapse" id="menu1">
					    	<ul className="navbar-nav ml-auto">
						      	<li className="nav-item">
						        	<button className="nav-link" href="#">Home</button>
						      	</li>
						      	<li className="nav-item">
						        	<button className="nav-link" href="#">About</button>
						      	</li>
						      	<li className="nav-item">
						        	<button className="nav-link" href="#">NFTs</button>
						      	</li>
						      	<li className="nav-item">
						        	<button className="nav-link" href="#">Your Collection</button>
						      	</li>
						      	<li className="nav-item">
						        	<button className="nav-link" href="#">RoadMap</button>
						      	</li>
						      	<li className="nav-item">
						        	<button className="nav-link" href="#">FAQs</button>
						      	</li>
					    	</ul>
					  	</div>
					</nav>
				</div>
				<div className="header-item">
					<h2>Cutymon</h2>
					<p>Cutymon is a collection of cuddly, adorable and unique NFT's creatures, who are powered by atmospheric magic and spiritual prowess. Adopt, catch, and train these mighty characters along the way as a trainer emerging from the blockchain.</p>
					<a href="/">Get Started</a>
				</div>
			</div>
		</header>
		{/* <!-- header-area end --> */}

		{/* <!-- about-area start --> */}
		<div className="about-area">
			<div className="container">
				<div className="about-area-inner">
					<div className="about-item">
						<img src="images/04.png" alt="" />
					</div>
					<div className="about-item2">
						<div className="about-item2-inner">
							<h2>About</h2>
							<p>Under the dewy night sky, a friendly face emerges from a nearby bush. It’s a curious Woltaros, brushing its tail against the grasses beneath the age. In the Twilight Forest, magic fills the air. Music echoes through the breeze, and Woltaros sit down next to a mystical egg, it begins to crackle and hatch. Could it be a new Cutymon being minted?
							<span>Like Woltaros, there are several cute creatures to collect, train, and battle with. Built around engaging story-telling and community, Cutymon brings people together ! Join our Discord and Twitter to be aware of the latest news !</span></p>
							{/* <a href="/">Mint Now</a> */}
              <p>{JSON.stringify({
                accountAddress, ownerBalances, userBalances, totalSupplies
              })}</p>
						</div>
              {/* <ReactPlayer url='/video/5.mp4' playing loop muted/> */}
					</div>
				</div>
			</div>
		</div>
		{/* <!-- about-area end -->	 */}

		{/* <!-- image-area start --> */}
		<div className="image-area">
			<div className="container">
				<div className="inner-container">
					<h2>Nfts</h2>
				</div>
				<div className="main-carousel">
					<div className="carousel-cell">
						<div className="image-item">
							<img src="images/07.png" alt="" />
							<h2>Woltaros</h2>
						</div>
					</div>
					<div className="carousel-cell">
						<div className="image-item">
							<img src="images/08.png" alt="" />
							<h2>Spinora</h2>
						</div>
					</div>
					<div className="carousel-cell">
						<div className="image-item">
							<img src="images/09.png" alt="" />
							<h2>Tigette</h2>
						</div>
					</div>
					<div className="carousel-cell">
						<div className="image-item">
							<img src="images/07.png" alt="" />
							<h2>Woltaros</h2>
						</div>
					</div>
					<div className="carousel-cell">
						<div className="image-item">
							<img src="images/08.png" alt="" />
							<h2>Spinora</h2>
						</div>
					</div>
					<div className="carousel-cell">
						<div className="image-item">
							<img src="images/09.png" alt="" />
							<h2>Tigette</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- image-area end -->	 */}

		{/* <!-- collection-area start --> */}
		<div className="collection-area">
			<div className="container">
				<div className="collection-area-inner">
					<div className="inner-container2">
						<div>
							<h2>Your Collection</h2>
						</div>
						<div>
							{accountAddress ? "" : <a href="/" onClick={(e) => {
								e.preventDefault()
								connect()
							}}>Connect Wallet</a>}
						</div>
					</div>
					<div className="collection-item">
						<ul>
              {[...Array(startAt - 1).keys()].map(i => i + 1).map(id => {
                let width = (ownerBalances[id - 1] / totalSupplies[id - 1]) * 160
                if(isNaN(width)) width=0
								return <li key={id}>
								<div className="collection-item-inner">
								<a href={osUrl + id}>
									<div className="collection-item-inner2">
										<img src="images/14.png" alt=""/>
										{!userBalances[id - 1] && <img src="images/15.png" alt=""/>}
										<img src="images/11.png" alt=""/>
										<img src={`images/${id}_192.png`} alt=""/>
									</div>
									</a>
									<div className="collection-item-inner4">
										<img src="images/fire.png" alt=""/>
									</div>
									<div className="collection-item-inner3">
										<span style={{width}}></span>
									</div>
									<h2>{ownerBalances[id - 1]}/{totalSupplies[id - 1]} Left</h2>
								</div>
							</li>

              }
							)}
							{extras.map(extra => {
								let {id} = extra
                let width = (ownerBalances[id - 1] / totalSupplies[id - 1]) * 160
								if(isNaN(width)) width=0
								let attributes = extra.attributes[0] || extra.attributes
								console.log(extra.attributes)
                return <li key={id}>
								<div className="collection-item-inner">
								<a href={osUrl + id}>
									<div className="collection-item-inner2">
										<img src="images/14.png" alt=""/>
										{!userBalances[id - 1] && <img src="images/15.png" alt=""/>}
										<img src="images/11.png" alt=""/>
										<img src={extra.smallImage} alt=""/>
									</div>
									</a>
									<div className="collection-item-inner4">
										<img src={`images/${attributes?.type}.png`} alt=""/>
									</div>
									<div className="collection-item-inner3">
										<span style={{width}}></span>
									</div>
									<h2>{ownerBalances[id - 1]}/{totalSupplies[id - 1]} Left</h2>
								</div>
							</li>
              }
							)}
						</ul>
						<p className="text-white">{JSON.stringify(extras)}</p>
					</div>
					<div className="collection-item2">
						{/* <a href="/">Expand</a> */}
					</div>
				</div>
			</div>
		</div>
		{/* <!-- collection-area end --> */}

		{/* <!-- road-map start --> */}
		<div className="roadmap-area">
			<div className="container">
				<div className="inner-container3">
					<h2>RoadMap</h2>
				</div>
				<div className="roadmap-item">
					<ul>
						<li>
							<img src="images/23.png" alt="" />
							<img src="images/23.png" alt="" />
							<h2>Q2 2022</h2>
							<p>Starting collection - Season 1</p>
						</li>
						<li>
							<img src="images/24.png" alt="" />
							<img src="images/24.png" alt="" />
							<h2>Q4 2022</h2>
							<p>Starting collection - Season 2</p>
						</li>
						<li>
							<img src="images/25.png" alt="" />
							<img src="images/25.png" alt="" />
							<h2>Q2 2023</h2>
							<p>Starting collection - Season 3 and Season 4</p>
						</li>
						<li>
							<img src="images/26.png" alt="" />
							<img src="images/26.png" alt="" />
							<h2>Q4 2023</h2>
							<p>Browser Game based on Cutymon - Fight over the top of ladder !</p>
						</li>
					</ul>
					<div className="roadmap-item-inner">
						<img src="images/27.png" alt="" />
					</div>
				</div>
			</div>
			<div className="roadmap-item3">
				<img src="images/29.png" alt="" />
			</div>
			<div className="roadmap-item4">
				<img src="images/30.png" alt="" />
			</div>
		</div>
		{/* <!-- road-map end --> */}

		{/* <!-- question-area start --> */}
		<div className="question-area">
			<div className="container">
				<div className="question-area-inner">
					<div className="question-item">
						<div className="question-item-inner">
							<h2>Frequently<br/> Asked<br/> questions </h2>
						</div>
						<img src="images/31.png" alt="" />
					</div>
					<div className="question-item2">
						<div className="ziehharmonika">
							<h3>Q.1 How do i buy a Cutymon ?</h3>
							<div>
								<p>1. Download the metamask.io extension on your browser.
								<span>2. Purchase Ethereum from various exchanges, such as Coinbase or Binance.</span>
								<span>3. Send Ethereum to your MetaMask wallet.</span>
								<span>4. Select Cutymon you want to mint</span>
								<span>5. Click “MINT” button, Metamask will popup asking for connection.</span>
								<span>6. Confirm the transaction and any associated fees.</span>
								<span>7. Once you have made your purchase, your Cutymon will appear in your collection website and on OpenSea!</span></p>
							</div>
							<span></span>
							<h3>Q.2 How many Cutymon can i mint ?</h3>
							<div>
								<p>Maximum 2x each unique Cutymon</p>
							</div>
							<span></span>
							<h3>Q.3 On which blockchain are the Cutymon ?</h3>
							<div>
								<p>Ethereum (ETH)</p>
							</div>
							<span></span>
							<h3>Q.4 What is the Mint Priced at ?</h3>
							<div>
								<p>The prices are indicated under each Cutymon image, according to their rarities</p>
							</div>
							<span></span>
							<h3>Q.5 What is the total supply ?</h3>
							<div>
								<p>The remaining supply is indicated under each Cutymon image, according to their rarities</p>
							</div>
							<span></span>
							<h3>Q.6 What if a Cutymon is out of stock ?</h3>
							<div>
								<p>You must go to Opensea.io and buy the desired Cutymon on the marketplace, from someone who already owns it. After that, it will appear in your collection.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- question-area end --> */}

		{/* <!-- footer-area start --> */}
		<footer className="footer-area">
			<div className="footer-area-inner">
				<div className="container">
					<div className="footer-item">
						<a href="/">Cutymon</a>
						<ul>
							<li><a href="/"><img src="images/35.png" alt="" /></a></li>
							<li><a href="/"><img src="images/36.png" alt="" /></a></li>
							<li><a href="/"><img src="images/37.png" alt="" /></a></li>
						</ul>
					</div>
				</div>
				<div className="footer-item2">
					<p>Copyright©Cutymon.com</p>
				</div>
			</div>
		</footer>

    {/* <!-- footer-area end --> */}

  </>
}

export default App